import React from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";

import HomePage3 from '../HomePage3/HomePage3';
import EventPage from '../EventPage/EventPage';
import ServicePageS2 from '../ServicePageS2/ServicePageS2';
import ErrorPage from '../ErrorPage/ErrorPage';

const AllRoute = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
         
          
          <Route path="/" element={<HomePage3 />} />
         
          <Route path="event" element={<EventPage />} />

          <Route path="service-s2" element={<ServicePageS2 />} />
          <Route path='404' element={<ErrorPage />} />

        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default AllRoute;
