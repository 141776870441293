import eimg1 from '../images/event/1.jpg'
import eimg2 from '../images/event/2.jpg'
import eimg3 from '../images/event/3.jpg'
import eimg4 from '../images/event/4.jpg'
import eimg5 from '../images/event/5.jpg'
import eimg6 from '../images/event/6.jpg'


import elimg1 from '../images/event/1.jpg'
import elimg2 from '../images/event/2.jpg'
import elimg3 from '../images/event/3.jpg'
import elimg4 from '../images/event/4.jpg'
import elimg5 from '../images/event/5.jpg'
import elimg6 from '../images/event/6.jpg'


import eSimg1 from '../images/event/1.jpg'
import eSimg2 from '../images/event/1.jpg'
import eSimg3 from '../images/event/1.jpg'
import eSimg4 from '../images/event/4.jpg'
import eSimg5 from '../images/event/5.jpg'
import eSimg6 from '../images/event/6.jpg'






const Events = [
    {
       Id:'1',
       eImg:eimg1,
       elImg:elimg1,
       eSimg:eSimg1,
       thumb:'Conference',   
       title:'Sabah Namazı EtkKİNLİĞİ',  
       slug:'Sabah Namazı EtkKİNLİĞİ',
       date:'HER PAZAR',
       time:'Namaz Sonrası Kahvaltı',
       location:'Huzur',
       progress:'90',
       raised:'2000.00',
       goal:'3000.00',
    },
    {
       Id:'2',
       eImg:eimg2,
       elImg:elimg2,
       eSimg:eSimg2,
       thumb:'Entertainment',    
       title:'Molla Fehİm DÜZGÜN Hoca İle SABAH NAMAZI BULUŞMASI', 
       slug:'İLİM MECLİSİ',
       date:'Her Pazar',
       time:'Kahvaltı Sonrası',
       location:'Kahvaltıya Gidilen Dergah',
       progress:'80',
       raised:'3000.00',
       goal:'4000.00',
    },
    {
      Id:'3',
      eImg:eimg3,
      elImg:elimg3,
      eSimg:eSimg3,
      thumb:'Conference',   
      title:'MOLLA FEHİM DÜZGÜN HOCA İLE İLİM MECLİSİ',  
      slug:'İLİM MECLİSİ',
      month:'25',
      date:'Her Hafta Çarşamba',
      time:'20.00 - 23.00 ',
      location:'Demetlale Dergahı',
      progress:'95',
      raised:'4000.00',
      goal:'5000.00',
   },
   {
      Id:'4',
      eImg:eimg4,
      elImg:elimg4,
      eSimg:eSimg4,
      thumb:'Entertainment',    
      title:'MOLLA FEHİM DÜZGÜN HOCA İLE MEVLÜT ÇALIŞMASI',  
      slug:'MEVLÜT ÇALIŞMASI',
      month:'25',
      date:'Her İlim Halkası Sonrası',
      time:'20.00 - 23.00 ',
      location:'İlim Halkasına Gidilen Dergah',
      progress:'80',
      raised:'3000.00',
      goal:'4000.00',
   },

   {
      Id:'5',
      eImg:eimg5,
      elImg:elimg5,
      eSimg:eSimg5,
      thumb:'Entertainment',    
      title:'MOLLA FEHİM DÜZGÜN HOCA İLE İLİM MECLİSİ',  
      slug:'İLİM MECLİSİ',
      month:'25',
      date:'Her Hafta Perşembe',
      time:'20.00 - 23.00 ',
      location:'Çiğdemtepe Dergahı',
      progress:'80',
      raised:'3000.00',
      goal:'4000.00',
   },

   {
      Id:'6',
      eImg:eimg6,
      elImg:elimg6,
      eSimg:eSimg6,
      thumb:'Entertainment',    
      title:'MOLLA FEHİM DÜZGÜN HOCA İLE İLİM MECL',  
      slug:'İLİM MECLİSİ',
      month:'25',
      date:'Her Hafta Cumartesi',
      time:'20.00 - 23.00 ',
      location:'Batıkent Dergahı',
      progress:'80',
      raised:'3000.00',
      goal:'4000.00',
   },


    
]

export default Events;