import React from 'react'
import { Link } from 'react-router-dom'

const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const SubmitHandler = (e) => {
    e.preventDefault()
}

const Footer = (props) => {
    return (
        <footer className="site-footer">
            <div className={`upper-footer ${props.ftClass}`}>
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="widget about-widget">
                                <div className="logo widget-title">
                                </div>
                                <p>Sultanımız Şeyh Seyyid Muhammed Saki el-Hüseyni (ks.) genç sofileri. </p>
                                <div className="social-widget">
                                    <ul>
                                        <li><Link onClick={ClickHandler} to="/"><i className="ti-facebook"></i></Link></li>
                                        <li><Link onClick={ClickHandler} to="/"><i className="ti-twitter-alt"></i></Link></li>
                                        <li><Link onClick={ClickHandler} to="/"><i className="ti-linkedin"></i></Link></li>
                                        <li><Link onClick={ClickHandler} to="/"><i className="ti-instagram"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                            
                        </div>
                        <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                            
                        </div>
                        <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="widget newsletter-widget">
                                <div className="widget-title">
                                    <h3>Haberler</h3>
                                </div>
                                <form onSubmit={SubmitHandler}>
                                    <input type="email" className="form-control" name="mail" id="mail"
                                        placeholder="Mail  Adresiniz..." required/>
                                    <input className="theme-btn" type="submit" value="Takip Edin" />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="lower-footer">
                <div className="container">
                    <div className="row g-0">
                        <div className="col col-lg-6 col-12">
                            <p className="copyright"> Copyright &copy; 2024 Serhendi Vakfı <Link onClick={ClickHandler} to="/"> Powered By Sofi Hasan</Link>.  All Rights Reserved.</p>
                        </div>
                       
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;