import simg1 from '../images/service/1.jpg'
import simg2 from '../images/service/1.jpg'
import simg3 from '../images/service/1.jpg'
import simg4 from '../images/service/1.jpg'
import simg5 from '../images/service/1.jpg'
import simg6 from '../images/service/1.jpg'

import sSimg1 from '../images/service-single/img-1.jpg'
import sSimg2 from '../images/service-single/img-2.jpg'
import sSimg3 from '../images/service-single/img-3.jpg'
import sSimg4 from '../images/service-single/img-4.jpg'
import sSimg5 from '../images/service-single/img-5.jpg'
import sSimg6 from '../images/service-single/img-6.jpg'

import icon1 from '../images/service/1.svg'
import icon2 from '../images/service/2.svg'
import icon3 from '../images/service/3.svg'
import icon4 from '../images/service/4.svg'
import icon5 from '../images/service/5.svg'
import icon6 from '../images/service/6.svg'


const Services = [
   {
      Id: '01',
      sImg: simg1,
      sSImg: sSimg1,
      icon:icon1,
      title: 'Demetlale DergahI',
      slug: 'Quran-Memorization',
      description: 'Demetevler, 395. Sk. No:3 D:A, 06200 Yenimahalle/Ankara, Yasin Apartmanı Bahçe Katı',
      iframe: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d642.8700791522829!2d32.78640270235562!3d39.96324543632629!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d349f51183f581%3A0xa2997fa24f1d9815!2sSerhendi%20Demet%20Dergah%C4%B1!5e0!3m2!1str!2str!4v1712374231540!5m2!1str!2str" width="330" height="200" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>' // Google'dan alınan iframe kodu
   },
   {
      Id: '02',
      sImg: simg2,
      sSImg: sSimg2,
      icon:icon2,
      title: 'İvedİk DergahI',
      slug: 'Special-Child-Care',
      description: 'Demetlale, eski 4 Cad, 405. Cd. 12/A, 06200 Yenimahalle/Ankara, Esim Optik Alt Katı ',
      iframe: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3057.846787542707!2d32.787929876531585!3d39.96717808287733!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d3494370093d4b%3A0x722f984c9083507b!2zRVPEsE0gT1BUxLBLICYgTEVOUw!5e0!3m2!1str!2str!4v1713028422403!5m2!1str!2str" width="330" height="200" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>'
   },
   {
      Id: '03',
      sImg: simg3,
      sSImg: sSimg3,
      icon:icon3,
      title: 'Özevler DergahI',
      slug: 'Mosque-Development',
      description: 'Özevler, 950. Sk. No:41, 06190 Yenimahalle/Ankara, Nimet Apartmanı İlk Kat',
      iframe:'<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3057.4792568716084!2d32.77371157653194!3d39.97539398238163!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d3496514125b29%3A0x5360912d82974860!2s%C3%96zevler%2C%20950.%20Sk.%20No%3A41%2C%2006190%20Yenimahalle%2FAnkara!5e0!3m2!1str!2str!4v1713028261011!5m2!1str!2str" width="330" height="200" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>'
   },
   {
      Id: '04',
      sImg: simg4,
      sSImg: sSimg4,
      icon:icon4,
      title: 'Batıkent DergahI',
      slug: 'Charity-&-Donation',
      description: 'Batıkent, İlkyerleşim Mah, Başkent Blv. No:204, 06370 Yenimahalle/Ankara, Türkiye, İkinci Giriş İkinci Kat',
      iframe:'<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3057.757504114624!2d32.7237639765316!3d39.96917408275671!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d349a217fe34af%3A0xc4c1e57fc24850aa!2zTGFiaXJlbnQgxLDFnyBNZXJrZXpp!5e0!3m2!1str!2sus!4v1713028147200!5m2!1str!2sus" width="330" height="200" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>'
   },

   {
      Id: '05',
      sImg: simg5,
      sSImg: sSimg5,
      icon:icon5,
      title: 'ÇiĞdemtepe DergahI',
      slug: 'Matrimonial',
      description: 'Çiğdemtepe, 1045. Sk. No:2, 06210 İvedik Osb/Yenimahalle/Ankara, Arka Girişteki İlk Kat',
      iframe:'<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3056.7546109115283!2d32.79730546053387!3d39.9915888373673!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d34bf2563312f1%3A0x7b9dc3e8ae00020b!2s%C3%87i%C4%9Fdemtepe%2C%201045.%20Sk.%20No%3A2%2C%2006210%20%C4%B0vedik%20Osb%2FYenimahalle%2FAnkara!5e0!3m2!1str!2str!4v1713027958665!5m2!1str!2str" width="330" height="200" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>'
   },
   {
      Id: '06',
      sImg: simg6,
      sSImg: sSimg6,
      icon:icon6,
      title: 'Gülüseven DergahI',
      slug: 'Funerals',
      description: 'Ragıp Tüzün, Ragıp Tüzün Cd. No:157, 06170 Yenimahalle/Ankara, 2. Kattaki İlk Daire',
      iframe:'<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3057.9029232688945!2d32.8049040765316!3d39.96592308295287!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d34952c4945437%3A0x8134cf6f88e2deae!2zR8O8bMO8c2V2ZW5sZXIgRGVybmXEn2k!5e0!3m2!1str!2str!4v1712375290433!5m2!1str!2str" width="330" height="200" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>'
   },
   {
      Id: '07',
      sImg: simg1,
      sSImg: sSimg1,
      icon:icon1,
      title: 'KarŞıyaka DergahI',
      slug: 'Quran-Memorization',
      description: 'Yeşilevler, 911. Cd., 06190 Yenimahalle/Ankara, Bina Girişi Yanında Aşağı Kata Olan Giriş',
       iframe:'<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d191.09757592843167!2d32.788214344818726!3d39.97356198886612!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d3495db5e134cb%3A0x23e1b432f924a91a!2sYeniinci%20Eczanesi!5e0!3m2!1str!2str!4v1713028004468!5m2!1str!2str" width="330" height="200" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>'
      },
   {
      Id: '08',
      sImg: simg2,
      sSImg: sSimg2,
      icon:icon2,
      title: 'Yakacık DergahI',
      slug: 'Special-Child-Care',
      description: 'Yakacık, 3990. Cd No:12, 06105 Yenimahalle/Ankara, Fıratpen Üst Katı, Girişi Yan Taraftan.' ,
      iframe:'<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d763.6078968374301!2d32.76507936966073!3d40.04346852491049!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d34b204e6cd3c7%3A0xa9d35e9423837cac!2sYakac%C4%B1k%2C%203585.%20Cd%20No%3A171%2C%2006105%20Yenimahalle%2FAnkara!5e0!3m2!1str!2str!4v1713026961804!5m2!1str!2str" width="330" height="200" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>'
   },
   {
      Id: '09',
      sImg: simg3,
      sSImg: sSimg3,
      icon:icon3,
      title: 'Çakırlar DergahI',
      slug: 'Mosque-Development',
      description: 'Turgut Özal, 2196. Sk. No:14, 06370 Yenimahalle/Ankara, Ayyıldız Apartmanı Girişi Yanı',
      iframe:'<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d382.1210638467439!2d32.69979373588111!3d39.986809033138435!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d3363effb1f3c1%3A0xf8f9cfc3e84dbc7c!2zQXl5xLFsZMSxeiBBcGFydG1hbsSx!5e0!3m2!1str!2str!4v1713027671631!5m2!1str!2str" width="330" height="200" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>'
   },
   {
      Id: '10',
      sImg: simg4,
      sSImg: sSimg4,
      icon:icon4,
      title: 'Serhat DergahI',
      slug: 'Charity-&-Donation',
      description: 'Serhat, 1319 Serhat Yapı Koop. No:21, 06374 Yenimahalle/Ankara Kapı No 1, -1. Kat',
      iframe:'<iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2570.622898320929!2d32.76250098098681!3d39.986037383802355!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMznCsDU5JzA5LjYiTiAzMsKwNDUnNDUuMSJF!5e0!3m2!1str!2str!4v1713027131615!5m2!1str!2str" width="330" height="200" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>'
   },
   {
      Id: '11',
      sImg: simg5,
      sSImg: sSimg5,
      icon:icon5,
      title: 'Avcılar DergahI',
      slug: 'Matrimonial',
      description: 'Avcılar, Karahöyük Cd. 63 A, 06210 Yenimahalle/Ankara Ekspress Market Üstü',
      iframe:'<iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3056.9711268443575!2d32.78210857598971!3d39.986750571511934!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMznCsDU5JzEyLjMiTiAzMsKwNDcnMDQuOSJF!5e0!3m2!1str!2str!4v1713027444378!5m2!1str!2str" width="330" height="200" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>'
   },
   // {
   //    Id: '12',
   //    sImg: simg6,
   //    sSImg: sSimg6,
   //    icon:icon6,
   //    title: 'Mesa DergahI',
   //    slug: 'Funerals',
   //    description: 'Betus acipsum srci fusid commod atutro. Enim facilisis donec gomdo enyn. Digsim amet feugiat nec proin lacinia.',
   //    iframe:''
   // },
  
]

export default Services;