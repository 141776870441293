import React from "react";
import { Link } from 'react-router-dom'
import hero1 from '../../images/slider/1.jpg'


const Hero3 = () => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (

        <section className="static-hero-s2">
            <div className="container-fluid">
                <div className="content">
                    <div className="icon">
                    </div>
                    <h2 className="title">السلام عليكم
                       <span/> <br/> Sofiler</h2>
                    <span className="subtitle">Bu internet sitesini Serhendi Gençlik Ankara Yenimahalle olarak yapmak istedik.
                        Bu sitenin amacı sofilerin yaptığımız etkinlikleri görebilmesi ve dergahlarımızın konumlarına rahatça erişebilmesidir.</span>
                    <div className="hero-btn">
                        <Link onClick={ClickHandler} to="/service-s2" className="theme-btn">Dergahlarımız</Link>
                    </div>
                </div>
            </div>
            <div className="image">
                <img src={hero1} alt="" />
            </div>
        </section>
    )
}

export default Hero3;