import React from 'react'
import { Link } from 'react-router-dom'
import abImg from '../../images/about-4.jpg'


const About3 = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="about-section-s3">
            <div className="container">
                <div className="wrap">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-12">
                            <div className="image">
                                <img src={abImg} alt="about" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="content">
                                <div className="section-title">
                                    <h2>Hakkımızda</h2>
                                    <h3>HAYIRLA GELDİNİZ</h3>
                                    <p>Bizler Sultanımız Şeyh Seyyid Muhammed Saki el-Hüseyni (ks.) genç sofileri olarak Yenimahalle'de 
                                        Allah (c.c)'ın rızasını gözeterek etkinlikler düzenliyoruz. </p>
                                    
                                </div>
                                <div className="about-bottom">
                                    <Link onClick={ClickHandler} to={'/event'} className="theme-btn">Etinliklerimiz Hakkında Daha Falza Bilgi </Link>

                                    
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About3;