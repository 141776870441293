// images
import blogImg1 from "../images/blog/img-1.jpg";
import blogImg2 from "../images/blog/img-2.jpg";
import blogImg3 from "../images/blog/img-3.jpg";





const blogs = [
    {
        id: '1',
        title: 'Sabah Namazı ETKİNLİĞİ',
        slug:'Sabah Namazı ETKİNLİĞİ',
        screens: blogImg1,
        description: 'Lorem sum has been the industry standard dummy of the text ever since the scrambled it to make.',
        author: 'Sultan-il Aşıkin',
        
       
        comment:'35',
        day:'28',
        month:'AUGUST',
        blClass:'format-standard-image',
        animation:'1200',
    },
    {
        id: '2',
        title: 'SABAH NAMAZI BULUŞMASI',
        slug:'SABAH NAMAZI BULUŞMASI',
        screens: blogImg2,
        description: 'Lorem sum has been the industry standard dummy of the text ever since the scrambled it to make.',
        author: 'Sultan-il Aşıkin',
        
   
        comment:'80',
        day:'25',
        month:'March',
        blClass:'format-standard-image',
        animation:'1400',
    },
    {
        id: '3',
        title: 'İLİM MECLİSİMİZ',
        slug:'İLİM MECLİSİMİZ',
        screens: blogImg3,
        description: 'Lorem sum has been the industry standard dummy of the text ever since the scrambled it to make.',
        author: 'Sultan-il Aşıkin',
      
        comment:'95',
        day:'23',
        month:'Sep',
        blClass:'format-video',
        animation:'1600',
    }
];
export default blogs;