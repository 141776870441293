import React, { useState, useEffect } from "react";

const PrayerTime = (props) => {
  const [prayerTimes, setPrayerTimes] = useState([]);
  const apiEndpoint = "https://api.collectapi.com/pray/all?data.city=ankara";
  const apiKey = "1RCfaFGBlA7xbMqX8CbgOJ:7t4HUkwnBzijKJtkop7232"; // API anahtarınızı buraya ekleyin

  useEffect(() => {
    const fetchPrayerTimes = async () => {
      try {
        const response = await fetch(apiEndpoint, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `apikey ${apiKey}`
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setPrayerTimes(data.result);
      } catch (error) {
        console.error("Error fetching prayer times:", error);
      }
    };

    fetchPrayerTimes();
  }, [apiEndpoint, apiKey]);

  return (
    <section className={`prayertime-section ${props.prClass}`}>
      <div className="container">
        <div className="prayertime-wrap">
          <div className="row g-0" id="prayerTimeRow">
            {prayerTimes.map((prayerTime, index) => (
              <div key={index} className="col-lg-2 col-md-4 col-sm-6 col-12">
                <div className="item">
                  <h2>{prayerTime.vakit}</h2>
                  <span>{prayerTime.saat}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PrayerTime;
